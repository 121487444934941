/* Modal.scss */

/* Global styles for the modal overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    display: none;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    transition: opacity 0.3s ease-in-out;

    &.open {
        display: flex;
        opacity: 1;
        overflow-y: hidden;
    }
}

/* Modal box styling */
.modal-container {
    display: flex;
    position: relative;
    flex-direction: column;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    height: 80%;
    max-width: 800px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.3s ease-out;

    @media (max-width: 768px) {
        width: 90%; /* Adjust width for small screens */
    }

    @media (max-width: 480px) {
        width: 95%; /* Further adjust for extra small screens */
        padding: 15px; /* Reduce padding */
    }
}

/* Modal close button */
.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 34px;
    font-weight: bold;
    color: #888;
    cursor: pointer;
    transition: color 0.3s ease;
    z-index: 9999;

    &:hover {
        color: #333;
    }
}

/* Title styling */
.modal-title {
    color: #333;
    margin-bottom: 15px;
    text-align: center;
    width: 100%;
}

/* Content styling */
.modal-content {
    font-size: 16px;
    color: #555;
    line-height: 1.6;
    text-align: center;
    height: 100%;
    justify-content: center;
}

/* Footer with buttons */
.modal-footer {
    display: flex;
    justify-content: center;
    gap: 10px;
    background-color: #888;

    position: absolute;
    border-top: 1px solid #000;
    height: 60px;
    width: 100%;
    bottom: 0;
    left: 0;
    border-radius: 0px 0px 8px 8px;
}

/* Modal buttons */
.modal-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s;

    &:hover {
        background-color: #0056b3;
        transform: scale(1.05);
    }

    &:focus {
        outline: none;
    }
}

/* Animation for fading in */
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: scale(0.9);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}
